import * as React from "react"
import Layout from "../../components/layout"
import Star from "../../components/star"
import '../../style.css'
import { Link } from "gatsby";

const GEMS = () => {
  return (
    <Layout>
        <div>
            <h1>GEMS EDUCATOR'S GUIDE</h1>
            <Star/>
            <h2>GEMS The Invisible Universe: The Electromagnetic Spectrum from Radio Waves to Gamma-rays Guide</h2>
            <p>This guide uses the mystery of gamma-ray bursts to teach about the Electromagnetic Spectrum through a series of activities.</p>
            <div>
              <h3>GEMS Science and Math Curriculum</h3>
                <p>Check out the website <a href="https://www.lhsgems.org/" target='_blank' rel="noreferrer">here</a></p>

              <h3>Supplemental Activity: Modeling the Universe</h3>
                <p><a href='/gems/modelingTheUniverse.pdf' target='_blank' rel="noreferrer">PDF</a> and <a href='/gems/modelingTheUniverse-disability.pdf' target='_blank' rel="noreferrer">Disability Accessible PDF</a></p>
                
              <h3>Presentation</h3>
                <p>Check out the <Link to='/ppt/gemsPP'>Invisible Universe power point presentation</Link></p>
            </div>
        </div>
    </Layout>
  )
}

export default GEMS
